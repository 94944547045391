<template>
  <div>
    <Header />
    <div class="warpper">
      <div class="message">
        <div class="Editing-materials">
          <div class="left">
            <div class="content">
              <router-view />
            </div>
          </div>
          <Right class="ri"/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layout/header";
import Footer from "@/components/layout/footer";
import Right from "@/views/myyezai/message/right";
// import local from "@/api/common/local.js";
// // followin 关注 , followingPage, browsingPage
// import { infocur } from "@/api/login/login.js";
export default {
  data() {
    return {
      data: null,
    };
  },
  created() {
    // this.getMessage();
  },
  methods: {
    // async getMessage() {
    //   let a = local.get("access_token");
    //   const { code, data } = await infocur(a);
    //   if (code == 0) {
    //     return data;
    //   }
    // },
  },
  components: {
    Header,
    Footer,
    Right,
  },
};
</script>

<style lang="less" scoped>
.warpper {
  display: flex;
  justify-content: center;
  background-color: #fafafaff;
  .message {
    width: 1200px;
    // padding-top: 86px;
    padding-top: 100px;
    // min-height: 900px;
    box-sizing: border-box;
    display: flex;
    // background-color: aqua;
    //  margin-bottom: 135px;
    //  margin-bottom: 200px;
    .Editing-materials {
      width: 1200px;
      // margin-bottom: 128px;
      display: flex;
      .left {
        width: 880px;
        // margin-right: 30px;
        // background-color: #ffffffff;
        // border-radius: 6px;
        // border: 1px solid #ececec;
        // min-height: 200px;
        .content {
          margin-right: 30px;
          background-color: #ffffffff;
          border-radius: 6px;
          border: 1px solid #ececec;
          margin-bottom: 70px;
        }
        // height: 0;
      }
      .ri {
        // height: 0;
        min-height: 426px;
      }
    }
  }
}
</style>