<template>
  <div>
    <div class="right-box">
      <div class="info">
        <div class="tou">
          <!-- <img :src="avatar || src" alt="" @click="jumpSb(0)" /> -->
          <!-- v-if="UserSex == 0" -->
          <!-- <img
            :src="isAvatar == 0 ? ManSrc || src : avatar || src"
            v-if="UserSex == 0"
            alt=""
            @click="jumpSb(0)"
          />
          <img
            :src="isAvatar == 0 ? WomanSrc || src : avatar || src"
            v-else
            alt=""
            @click="jumpSb(0)"
          /> -->
          <img :src="mosrc || src" alt="" @click="jumpSb(0)" />
          <p v-if="avatarAppStatus === '2'">审核中</p>
        </div>
        <p class="info-name" @click="jumpSb(1)">{{ nickName }}</p>
        <div class="hui">
          <img
            :src="isVipMember == 0 ? `${NonMemberIcon}` : `${MemberIcon}`"
            alt=""
            @click="jumpSb(2)"
          />
          <img
            :src="
              realAuthStatus == 2
                ? `${realAuthStatusIcon}`
                : `${NonRealAuthStatusIcon}`
            "
            alt=""
            @click="jumpSb(3)"
          />
        </div>
      </div>
      <ul>
        <li @click="Jump(1)">
          <p style="font-weight: bold">关注我的</p>
          <span>{{ ok.followCount }}</span>
        </li>
        <li @click="Jump(2)">
          <p style="font-weight: bold">消息</p>
          <!-- <span>{{ ok.messageCount }}</span> -->
          <span>{{ meessCont }}</span>
        </li>
        <li @click="Jump(3)">
          <p style="font-weight: bold">谁看过我</p>
          <!-- <span>{{ ok.broswseCount }}</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import local from "@/api/common/local.js";
// followin 关注 , followingPage, browsingPage
import { infocur } from "@/api/login/login.js";
import { msgTotal } from "@/api/member/member.js";
import { allAppStatus } from "@/api/member/member.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { mapState ,mapMutations} from "vuex";

export default {
  data() {
    return {
      gznum: null,
      MemberIcon: require("@/assets/images/tubiao/yezai_member.png"),
      NonMemberIcon: require("@/assets/images/tubiao/yezai_memberQ.png"),
      realAuthStatusIcon: require("@/assets/images/tubiao/yezai_auth_bb.png"),
      NonRealAuthStatusIcon: require("@/assets/images/tubiao/yezai_auth_b.png"),
      //默认头像
      src: require("@/assets/images/message/tou.png"),
      ManSrc: require("@/assets/images/message/manTou.png"),
      WomanSrc: require("@/assets/images/message/womanTou.png"),
      mosrc: null,

      avatar: null,
      nickName: "",
      liulan: null,
      ok: {},
      isVipMember: "",
      realAuthStatus: "",
      id: "",
      isAvatar: "",
      UserSex: "",
      HYmess: [],
      avatarAppStatus: "3",
      Num:0,
      // meessCont: 0,
    };
  },
  created() {
    this.cs();
  },
  computed: mapState(["meessCont"]),
  methods: {
     ...mapMutations(['messLL']),
    // 跳转
    jumpSb(i) {
      if (i == 0) {
        let rel = this.$router.resolve({
          path: "/n/user/_album",
        });
        window.open(rel.href, "_blank");
      } else if (i == 1) {
        let rel = this.$router.resolve({
          path: "/u",
          query: {
            id: this.id,
          },
        });
        window.open(rel.href, "_blank");
      } else if (i == 2) {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else if (i == 3) {
        let rel = this.$router.resolve({
          path: "/n/user/_auth",
        });
        window.open(rel.href, "_blank");
      }
    },
    async cs() {
      let a = local.get("access_token");
      const { code, data } = await infocur(a);
      if (code == 0) {
        //头像
        this.avatar = ossL(data.user.avatar);
        this.isAvatar = data.user.isAvatar;
        this.nickName = data.user.nickName;
        this.isVipMember = data.user.isVipMember;
        this.realAuthStatus = data.user.realAuthStatus;
        this.id = data.user.id;
        this.UserSex = data.user.sex;
        if (this.isAvatar == 0) {
          if (this.UserSex == 0) {
            this.mosrc = this.ManSrc;
          } else {
            this.mosrc = this.WomanSrc;
          }
        } else {
          this.mosrc = this.avatar;
        }
        const da = await msgTotal();
        if (da.code == 0) {
          this.ok = da.data;
        }
        // this.HxLogin();
        // console.log("数据", this.meessCont);
      }
      const L = await allAppStatus();
      if (L.code == 0) {
        // console.log(D.data);
        this.avatarAppStatus = L.data.avatarAppStatus;
      }
    },
    HxLogin() {
      const _that = this;
      // const token = localStorage.getItem("token");
      var options = {
        user: _that.id,
        pwd: "yezai_user@easemob",
        // appKey: _that.WebIM.config.appkey,
        appKey: _that.$WebIM.config.appkey,
        success: function () {
          console.log("登录成功");
        },
        error: function () {},
      };
      // _that.WebIM.conn.open(options);
      _that.$WebIM.conn.open(options);

      // _that.WebIM.conn.listen({
      _that.$WebIM.conn.listen({
        onOpened: function (message) {
          // localStorage.setItem("HUser", "1234");
          console.log("00", message);
          _that.liao();
        },
        onTextMessage: function (message) {
          console.log("onTextMessage", message);
          _that.liao();
        },
      });
    },
    liao() {
      this.HYmess = [];
      // const _that = this;
      this.$WebIM.conn.getSessionList().then((res) => {
        const data = res.data.channel_infos;
        // _that.HYmess = res.data.channel_infos;
        //遍历处理数据
        data.map((i) => {
          // 也在小助手
          if (
            i.channel_id.substring(
              i.channel_id.indexOf("_") + 1,
              i.channel_id.indexOf("@")
            ) == ""
          ) {
            console.log("001");
          } else {
            this.HYmess.push({
              channel_id: i.channel_id.substring(
                i.channel_id.indexOf("_") + 1,
                i.channel_id.indexOf("@")
              ),
              payload: JSON.parse(i.meta.payload).bodies[0].msg,
              timestamp: i.meta.timestamp,
              unread_num: i.unread_num,
            });
            // let a = 0;
            console.log("消息", this.HYmess);

            // console.log("消息", a, this.HYmess, this.HYmess.length);
          }
        });
        for (let i = 0; i < this.HYmess.length; i++) {
          console.log("消息", this.HYmess[i].unread_num);
          // this.meessCont += Number(this.HYmess[i].unread_num);
          this.Num += Number(this.HYmess[i].unread_num);
          this.messLL({number:this.Num})
        }
      });

      // console.log(this.HYmess instanceof Array)
      // this.meessCont = a;
    },
    Jump(num) {
      if (num == 2) {
        let rel = this.$router.resolve({
          path: "/n/message",
        });
        window.open(rel.href, "_blank");
      } else if (num == 1) {
        let rel = this.$router.resolve({
          path: "/n/message/_visit",
          query: {
            id: num,
          },
        });
        window.open(rel.href, "_blank");
      } else if (num == 3) {
        let rel = this.$router.resolve({
          path: "/n/message/_follow",
          query: {
            id: num,
          },
        });
        window.open(rel.href, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.right-box {
  width: 290px;
  height: 384px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px 40px;
  border-radius: 6px;
  border: 1px solid #ececec;

  .info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    .tou {
      margin-bottom: 12px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        cursor: pointer;
      }
      position: relative;
      overflow: hidden;
      p {
        position: absolute;
        bottom: 0px;
        width: 100%;
        font-size: 12px;
        text-align: center;
        color: #fff;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .hui {
      width: 100%;
      height: 16px;
      margin-top: 12px;
      display: flex;
      justify-content: center;
      img {
        margin-left: 6px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
    .info-name {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      cursor: pointer;
    }
  }
  ul {
    margin-top: 50px;
    li {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      line-height: 45px;
      font-size: 14px;
      color: #666;
    }
  }
}
</style>
